<template>
  <div>
    <Modal class="pop-contacts-mobile" footer-hide v-model="showContacts">
        <div>
          <div class="top">
          <div class="profile">{{company._source.company}}</div>
        </div>
          <div class="top-line"></div>
          <div class="contacts">
            <div class="contact-content">
              <template v-if="!contactsLoading && info.length > 0">
                <div class="content-item" v-for="(item,key) in info" :key="key">
                  <div class="left">
                    <div class="name">{{item.firstName}} {{item.lastName}}</div>

                    <div class="icons">
                      <div class="right-item right-item-phone"   >
                        <img src="@/assets/contact/has-email.png" v-if="item.hasEmail" @click="copy(item,1)"/>
                        <img src="@/assets/contact/has-email.png" class="not-has-icon" v-else/>
                      </div>
                    </div>

                    <!--                    <div class="email" @click="copy(item.value)">{{item.value}}</div>-->
                  </div>
                  <div class="middle d-flex">
                    <div class="middle-item" v-if="item.jobTitle">{{item.jobTitle}}</div>
                  </div>

                </div>
              </template>
              <template v-else-if="contactsLoading">
                <div class="contacts-loading">
                  <img src="@/assets/loading.gif" />
                </div>
              </template>
              <template v-else>
                <div class="contacts-loading">
                  No Contacts
                </div>
              </template>
            </div>

            <div class="contacts-page">
              <div class="prev" >
                <img src="@/assets/contact/prev.png" v-if="pageNum > 1" @click="prevPage()"/>
                <img src="@/assets/contact/prev-2.png" v-else/>
              </div>
              <div class="next">
                <img src="@/assets/contact/next.png" @click="nextPage()" v-if="page.max / perPage > pageNum"/>
                <img src="@/assets/contact/next-2.png" v-else/>
              </div>
            </div>
          </div>
        </div>

    </Modal>
  </div>
</template>

<script>
import api from "@/api";

const {
  _getCompanyContacts,_getContactDetail
} = api;
export default {
  props: [],
  computed: {

  },
  components: {

  },
  data() {
    return {
      showContacts: false,
      loading: false,
      copyData: '',
      pageNum: 1,
      perPage: 5,
      contactsLoading: true,
      changePage: false,
      info: {
        organization: null,
        country: null,
        domain: null,
      },
      page: {},
      company: {},
    };
  },
  mounted() {},
  filters: {

  },
  methods: {
    jumpWebsite(url) {
      let newStr1 = url.indexOf("http  //");
      let newStr2 = url.indexOf("http");
      if (newStr1 == 0) {
        window.open("http://" + url.substr(8));
      } else if (newStr2 == -1) {
        window.open("http://" + url);
      } else {
        window.open(url);
      }
    },
    getContacts(item){
      this.info = {
        organization: null,
        country: null,
        domain: null,
      };
      this.company = item;
      this.getData();
    },
    nextPage(){
      this.pageNum++;
      this.changePage = true;
      this.getData();
    },
    prevPage(){
      this.pageNum--;
      this.changePage = true;
      this.getData();
    },
    getData(){
      this.contactsLoading = true;
      _getCompanyContacts({
        company: this.company._source.company,
        page: this.pageNum,
        code: this.company._source.company_code,
      })
        .then(response => {
          if(response.code == 0){
            this.info = response.data.data;
            this.page = {'max':response.data.maxResults };
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.contactsLoading = false;
        });
    },
    copy(data,num){
      _getContactDetail({
        id:data.id
      })
        .then(response => {
          if(response.code == 0){
            if(num == 1){
              var copyData = response.data.email;
            }else{
              var copyData = response.data.phone;
            }
            let elInput = document.createElement('input')
            elInput.value = copyData;
            document.body.appendChild(elInput)
            elInput.select()
            console.log(elInput, elInput.value)
            document.execCommand("Copy")
            elInput.remove()
            this.$Message.success("Copied");

          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {

        });
      return;


    }
  }
};
</script>
<style lang="less" scoped>
.pop-contacts-mobile{
  .d-flex{
    display: flex;
  }
    .top{
      .profile{
        font-weight: 600;
        font-size: 18px;
        line-height: 20px;
        /* identical to box height */
        color: #222222;
        margin-top: 5px;
      }
    }
  .top-line{
    width: 290px;
    height: 0px;

    border: 1px solid #e7e7e7;
    margin: 15px auto 15px auto;
  }
  .main{
    justify-content: space-between;
    .main-left{
      margin-right: 0px;
      .name{
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;

        color: #333333;
      }
      .address{
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        color: #3E3E3E;
        margin-top: 5px;
        width: 250px;

      }
      .media{
        margin-top: 13px;
        .media-item{
          cursor: pointer;
          img{
            height: 22px;
            width: auto;
          }

          margin-right: 10px;
        }
      }
    }
    .main-right{
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #555555;
      width: 280px;
      padding-top: 5px;
    }
  }
  .contacts{
    /*width: 295px;*/
    /*height: 352px;*/

    background: #EFEFEF;
    border-radius: 10px;
    padding: 10px 20px 20px 10px;
    margin-top: 5px;
    .title{
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      /* identical to box height */


      color: #222222;
    }
    .contact-line{
      /*width: 250px;*/
      height: 0px;

      border: 1px solid #d6d6d6;
      margin: 10px auto 10px auto;
    }
    .contact-content{
      .contacts-loading{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 30px;
          height: 30px;
        }
      }
      .content-item{
        /*display: flex;*/
        border-bottom: 1px solid #dadada;;
        padding-bottom: 7px;
        padding-top: 7px;
        .left{
          /*width: 70%;*/
          display: flex;
          justify-content: space-between;
          .icons{
            display: flex;
            .right-item{
              cursor: pointer;
              img{
                width: 20px;
                height: auto;
              }

              margin-right: 8px;
            }
            .right-item-phone{
              cursor: pointer;
            }
          }
          .name{
            font-weight: 500;
            font-size: 15px;
            line-height: 17px;
            /* identical to box height */


            color: #222222;
            margin-bottom: 4px;
            /*height: 17px;*/

          }
          .email{
            font-weight: 400;
            font-size: 14px;
            line-height: 15px;
            /* identical to box height */


            color: #222222;
            max-width: 90%;
            word-break: break-all;
            cursor: pointer;
          }
        }
        .middle{
          width: 100%;
          flex-wrap: wrap;
          align-items: center;
          display: flex;
          .middle-item{
            background: #D3D9DE;
            border-radius: 6px;
            padding: 2px 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 7px;
            margin-top: 3px;
            min-height: 30px;
            font-size: 12px;
            max-width: 80%;
          }
        }
        .right{
          width: 30%;
          align-items: center;
          flex-wrap: wrap;
          .right-item{
            cursor: pointer;
            img{
              width: 20px;
              height: auto;
            }

            margin-right: 13px;
          }
          .right-item-phone{
            cursor: pointer;
          }

        }
        .bottom{
          margin-top: 10px;
        }
      }
      .content-line{
        /*width: 300px;*/
        height: 0px;

        border: 1px solid #dadada;
        margin: 10px auto;
      }
    }
    .contacts-page{
      display: flex;
      justify-content: space-between;
      width: 50px;
      margin: 30px auto 0 auto;
      div{
        cursor: pointer;
        img{
          width: 10px;
          height: 17px;
        }
      }
    }
  }
  }
</style>
<style lang="less">
  .pop-contacts-mobile {
    .ivu-modal {
      width: 350px !important;
      margin: 0 auto !important;

      .ivu-modal-content {
        padding-left: 0;
        padding-right: 0;
        border-radius: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        .ivu-modal-body{
          padding-left: 15px;
          padding-right: 15px;
        }


      }
    }
  }
</style>
